<template>
  <div class="nav-search-results" v-if="getSuggestions.length > 0">
    <TransitionResults>
      <ResultsTitle>
        {{ UI_ELEMENT(`search_results_subtitle_suggestions`) }}
      </ResultsTitle>
    </TransitionResults>
    <TransitionResults group>
      <NavSearchResultsItem
        v-for="item in getSuggestions"
        :key="item.sys.id"
        :content="item"
        @clicked="() => handleResultClick(item)"
      />
    </TransitionResults>
  </div>
</template>

<script>
import { mapGetters } from "vuex"

import NavSearchResultsMixin from "./NavSearchResultsMixin"

import NavSearchResultsItem from "./NavSearchResultsItem.vue"

export default {
  mixins: [NavSearchResultsMixin],

  components: {
    NavSearchResultsItem
  },

  computed: {
    ...mapGetters("content", ["GET_PRODUCT_ENTRY", "GET_SITE_CONFIG"]),

    getSuggestions() {
      return (
        (this.GET_SITE_CONFIG.searchSuggestionsCollection &&
          this.GET_SITE_CONFIG.searchSuggestionsCollection.items.length > 0 &&
          this.GET_SITE_CONFIG.searchSuggestionsCollection.items) ||
        []
      )
    }
  }
}
</script>

<style lang="scss">
@import "./NavSearchResults.scss";
</style>
